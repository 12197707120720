import React, { useState, useRef } from 'react';
import PropTypes from "prop-types";
import ContactPopup from '../Popup/contact-popup';
// import { useMenuQuery } from '../../hooks/useMenuQuery';

const Header = (props) => {
    const [email, setEmail] = useState('');
    const navClass = props.home ? 'navbar navbar-expand-lg navbar-dark bg-dark' : 'navbar navbar-expand-lg navbar-light bg-light';
    const textClass = props.home ? 'text-decoration-none text-white' : 'text-decoration-none text-dark';
    const childRef = useRef();
    // const { site, menu } = useMenuQuery();
    // const datamenu = menu.menuItems.nodes;
    const openPopup = () => {
        console.log('open contact popup');
        childRef.current?.open(email);
    }
    return (
        <>
            <header className={ props.home ? 'home' : ''}>
                <nav className={ navClass }>
                    <div className="container">
                        <a className="navbar-brand" href="/"><img src="/assets/img/logo.svg" alt="Logo"
                            width="120" /></a>
                        <div className="d-flex flex-column gap-4 flex-grow-1 ps-4">
                            <div className="d-flex justify-content-end gap-3 top-links">
                                <a href="tel:8887238320" class={ textClass }><i
                                    className="fa fa-phone-square"></i> <span className="ps-2">888 723 8320</span></a>
                                <a href="/video-help" class={ textClass }><i
                                    className="fa fa-video"></i> <span className="ps-2">Video Help</span></a>
                                <a href="/submit-request" class={ textClass }><i
                                    className="fa fa-file-pen"></i> <span className="ps-2">Support</span></a>
                                <a href="/contact" class={ textClass }><i
                                    className="fa fa-envelope"></i> <span className="ps-2">Contact Us</span></a>
                                <a href="https://app.powerpatent.com/docket/auth" target='_blank' class={ textClass }><i className="fa fa-lock"></i>
                                    <span className="ps-2">Login</span></a>
                            </div>
                            <div className="text-lg-start text-end">
                                <button className="navbar-toggler me-lg-0 px-2 me-sm-5 bg-white" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <i className="fa fa-bars text-dark"></i>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav w-100 justify-content-between flex-wrap align-items-center">
                                        <li className="nav-item">
                                            <a className={"nav-link " + (!props.menu || props.menu==='home'? 'active' : '')} href="/">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link " + (props.menu==='how'? 'active' : '')} href="/how-it-works">How It Works</a>
                                        </li>
                                        {/* <li class="nav-item dropdown">
                                            <a class={"nav-link dropdown-toggle " + (props.menu==='sol'? 'active' : '')} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Solutions
                                            </a>
                                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><a class="dropdown-item" href="/companies">Companies</a></li>
                                                <li><a class="dropdown-item" href="/law-firms">Law Firms</a></li>
                                            </ul>
                                        </li> */}
                                        <li class="nav-item dropdown">
                                            <a class={"nav-link dropdown-toggle " + (props.menu==='info'? 'active' : '')} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Info
                                            </a>
                                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><a class="dropdown-item" href="/industries/electronics">Electronics</a></li>
                                                <li><a href="/industries/software/" class="dropdown-item">Software</a></li>
                                                <li><a href="/industries/communications/" class="dropdown-item">Communications</a></li>
                                                <li><a href="/industries/blockchain-and-fintech/" class="dropdown-item">Blockchain and Fintech</a></li>
                                                <li><a href="/industries/artificial-intelligence-ai/" class="dropdown-item">Artificial Intelligence (AI)</a></li>
                                                <li><a href="/industries/nanotechnology/" class="dropdown-item">Nanotechnology</a></li>
                                                <li><a href="/industries/3-d-printing/" class="dropdown-item">3D Printing</a></li>
                                                <li><a href="/industries/autonomous-vehicles/" class="dropdown-item">Autonomous Vehicles</a></li>
                                                <li><a href="/industries/metaverse/" class="dropdown-item">Metaverse</a></li>
                                                <li><a href="/industries/cannabis-patents-and-trademarks/" class="dropdown-item">Cannabis Patents and Trademarks</a></li>
                                                <li><a href="/industries/digital-healthcare/" class="dropdown-item">Digital Healthcare</a></li>
                                                <li><a href="/industries/medical-devices/" class="dropdown-item">Medical Devices</a></li>
                                                <li><a href="/industries/animal-health/" class="dropdown-item">Animal Health</a></li>
                                                <li><a href="/industries/infectious-diseases/" class="dropdown-item">Infectious Disease</a></li>
                                                <li><a href="/industries/prescription-otc-drugs/" class="dropdown-item">Prescription &amp; OTC Drugs</a></li>
                                                <li><a href="/industries/consumer-products/" class="dropdown-item">Consumer Products</a></li>
                                                <li><a href="/industries/food-science-packaging/" class="dropdown-item">Food Science &amp;Packaging</a></li>
                                                <li><a href="/industries/internet/" class="dropdown-item">Internet</a></li>
                                                <li><a href="/industries/chemical-products/" class="dropdown-item">Chemical Products</a></li>
                                                <li><a href="/industries/industrial-products/" class="dropdown-item">Industrial Product</a></li>
                                                <li><a href="/industries/biopharmaceuticals/" class="dropdown-item">Biopharmaceuticals</a></li>
                                                <li><a href="/industries/therapeutic-antibodies/" class="dropdown-item">Therapeutic Antibodies</a></li>
                                                <li><a href="/industries/industrial-and-agricultural-biotech/" class="dropdown-item">Industrial and Agricultural Biotech</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link " + (props.menu==='tools'? 'active' : '')} href="/free-tools">Free Tools</a>
                                        </li>

                                        <li class="nav-item dropdown">
                                            <a class={"nav-link dropdown-toggle " + (props.menu==='about'? 'active' : '')} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            About
                                            </a>
                                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><a class="dropdown-item" href="/info">Company Info</a></li>
                                                <li><a class="dropdown-item" href="/contact">Contact</a></li>
                                                <li><a class="dropdown-item" href="/press">Press</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a class={`nav-link ${props.menu==='blog'? 'active' : ''}`} href="/blog">Blogs</a>
                                        </li>
                                        <li className="ms-xl-0 ms-lg-auto ms-0">
                                            <button onClick={openPopup} className="btn btn-warning nav-btn d-lg-block d-none">Request Demo</button>
                                        </li>
                                    </ul>

                                </div>
                                <button onClick={openPopup} className="btn btn-warning nav-btn d-lg-none d-inline-block">Request Demo</button>
                            </div>
                        </div>
                    </div>
                </nav>
                {
                    props.home ? 
                    // 
                    <div className="container py-md-5">
                        <div className="row py-md-5">
                            <div className="col-lg-6 col-md-10 py-5">
                                <div className="hero py-md-5">
                                    <h1 className="fw-bolder text-primary pt-3 pb-3">PATENT DRAFTING MADE EASY</h1>
                                    <h4 className=" text-white pb-5">Created By Patent Lawyers, For Patent Lawyers. Enhanced by <b
                                        className="text-primary">Generative AI</b></h4>
                                    <div className="d-flex gap-4 pt-md-3 pt-0 pb-5 flex-md-row flex-column">
                                        <input value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder="Enter your work email address"
                                            className="form-control form-control-lg" />
                                        <button onClick={openPopup} className="btn btn-warning btn-lg text-nowrap fw-bold w-max">Request Demo</button>
                                    </div>
                                    {/* <p className="h-note text-white"><i className="fa fa-credit-card me-2"></i> No credit card required</p> */}
                                </div>
                            </div>
                        </div>
                    </div> 
                    
                    : 

                    null
                }
            <ContactPopup ref={childRef}></ContactPopup>
            </header>
        </>
    )
}

Header.propTypes = {
    home: PropTypes.bool.isRequired,
    menu: PropTypes.string
};

export default Header;